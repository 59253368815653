@import '../assets/scss/index';
@import '../assets/scss/common/FormLayout';

.mainButton {
  @include media-breakpoint-up(sm) {
    &:nth-child(3) {
      flex-basis: 100%;
    }
    flex-basis: calc(50% - 5px);
  }
}