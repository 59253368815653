@import '../assets/scss/index';

.containerWrapper {
  display: flex;
  min-height: calc(100% - #{$footerHeight});
  @include media-breakpoint-up(sm) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 16px;
    padding-bottom: 50px;
  }
  justify-content: center;
  //height: 100%;
}

.error {
    color: #CC1818;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 20px;
    margin-bottom: 16px;
    background: #FBDBDB;

  p {
    margin: 0;
  }
}

.back {
  display: inline-flex;
  align-items: center;
  color: #C32B3E;
  text-decoration: none;
  margin-bottom: 8px;
}

.container {
  width: 600px;

}

.header {
  font-weight: 700;
  margin-bottom: 8px;
}

.timeInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: center;
}

.selectTime {
  margin-left: auto;
}

@include media-breakpoint-down(sm) {
  .titleMobile {
    flex: none!important;
  }
}

@include media-breakpoint-down(sm) {
  .timeInfo {
    flex-direction: column;
  }
}

.buyButton {
  @include media-breakpoint-down(sm) {
    flex-basis: calc(100% - 10px);
  }
  @include media-breakpoint-up(sm) {
    &:nth-child(3) {
      flex-basis: calc(100%);
    }
    flex-basis: calc(50% - 5px);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  background: #1D4F8B;
  border-radius: 50px;
  border: 0;
  font-weight: 500;
  outline: 0;
  height: 50px !important;

  &:disabled {
    opacity: 40%;
  }

  .actionIcon > svg * {
    fill: #FFFFFF;
  }

  .googleIcon > svg * {
    fill: black;
  }
}

.appleButton {
  background: #000000;
  font-weight: 600;
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  color: white;
}

.googleButton {
  background: white;
  font-weight: 500;
  color: #000000;
}

.emailButton {
  outline: 1px solid #CC1818;
  background: transparent;
  font-weight: 500;
  color: #CC1818;

  &:focus {
    outline: 1px solid #CC1818 !important;
  }
}

.buttonFull {
  flex-basis: calc(100%)!important;
}

.buyTicket {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /*@include media-breakpoint-down(sm) {
    flex-direction: column;
  }*/
}

.item {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 20px;
  box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.03);
  background-color: white;
  margin-bottom: 12px;

  .info {
    transition: all 0.3s ease;
    display: flex;
    height: 64px;
    padding: 14px 16px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;

    .icon {
      color: var(--secondary-light-blue, #6E8CB8);
      text-align: center;
      font-size: 16px;
      width: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 16px */
    }

    .titleBuy {
      margin-right: auto;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: -4px;
      flex: 1 0 0;

      .itemTitle {
        color: var(--Greys-Grey-6-Dark, #1C1C1E);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }

      .itemTitleBuy {
        //justify-content;
      }

    }

    .itemEnd {
      display: flex;
      padding-right: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-up(sm) {
      .itemEndExtended {
        min-width: 16rem;
      }
    }
    @include media-breakpoint-down(sm) {
      .itemEndExtended {
        width: 100%;
      }
    }

    .textDanger {
	    color: $red;
    }
    .textMuted {
      color: #666666;
    }

    .text500 {
      font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
      .inputMobileMax {
        max-width: 141.5px;
      }
    }

    .input {
      width: 100%;
      background: #F3F3F5;
      height: 40px;
      min-width: 141.5px;
      border-radius: 20px;
      border: 1px solid #3A3C42;
      padding: 8px 16px 8px 16px;

      &::placeholder {
        color: #757575;
        font-style: italic;
        font-size: small;
        opacity: 0.7;
      }

      &:focus {
        outline: 0;
      }
    }

    .textPricing {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      > svg {
        margin-left: 10px;
      }
    }
  }

}

.appleIcon > svg * {
  fill: white;
}

.radioGroup {
  margin: 10px 0;
}

.radioOption {
  display: block;
  position: relative;
  padding: 16px;
  margin-bottom: 15px;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.radioOption:last-child {
  border-bottom: none;
}

.radioOption input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioOption .radioLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.radioLabel {
  span:nth-child(1) {
    margin-right: auto;
  }
}

.radioOption .radioLabel:before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 100%;
  border: 1px solid #6E8CB8;
  background: #F3F3F5;
  vertical-align: middle;
}

.radioOption input[type="radio"]:checked + .radioLabel:before {
  background-color: #1D4F8B;
  border: 1px solid #1D4F8B;
  box-shadow: inset 0 0 0 4px #F3F3F5;
}

.inputError {
  transition: all 0.3s ease;
  border-radius: 20px;
  background: rgba(235, 64, 52, 0.2);

  .itemTitle {
    transition: all 0.3s ease;
    color: #CC1818!important;
  }

  svg {
    fill: #CC1818;
  }

  input::placeholder {
    color: #CC1818!important;
  }
}

.inputWarning {
  transition: all 0.3s ease;
  border-radius: 20px;
  background: rgba(255, 163, 64, 0.2);

  .itemTitle {
    transition: all 0.3s ease;
    color: #FFA340!important;
  }

  svg {
    fill: #FFA340;
  }

  input::placeholder {
    color: #FFA340!important;
  }
}

.inputErrorColor {
  color: #CC1818!important;
  text-align: center;
}

.warningOverlayContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0F0F0;
  box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
  border-radius: 20px;
  z-index: 99999999999;
  flex-direction: column;
  padding: 50px;

  @include media-breakpoint-down(sm) {
    width: calc(100% - 26px);
  }


  .warningOverlayText {
    color: black;
    width: 300px;
    margin-bottom: 2rem;
  }

  .warningButtons {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    height: 110px!important;
  }
}

.warningOverlay {
  height: 100dvh;
  width: 100%;
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  background: rgba(22, 24, 30, 0.48);
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  flex-direction: column;
  color: white;
  text-align: center;
}

.blockInfoText {
	display: flex;
	margin-bottom: 0;
	color: #666666;
	height: 44px;
	width: 100%;
	align-items: center;
	justify-content: center;
}