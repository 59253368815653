@import '../../assets/scss/index';

.progressInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    padding: 12px 16px !important;
  }
}

.progressTitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.progressBar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: linear-gradient(224.93deg, rgba(150, 170, 183, 0.2) -0.12%, rgba(114, 136, 150, 0.2) 100%);
  border-radius: 8px;

  .progress {
    height: 8px;
    background-color: #1D4F8B;
    border-radius: 8px;
    width: 0;
    transition: width 0.5s ease-in-out;

    &.progressDanger {
      background-color: $red;
      width: 100%;
    }
  }
}

.progressBarParkingSession {
  background: linear-gradient(224.93deg, rgba(150, 170, 183, 0.2) -0.12%, rgba(114, 136, 150, 0.2) 100%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .progress {
    //border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}