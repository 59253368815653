@import '../../assets/scss/index';

.bottomPanel {
	position: absolute;
	bottom: 32px;
	right: 10px;
	width: 364px;
}

.mapResultsContainer {

	.mapResults {
		border-radius: 20px !important;
		background: var(--Background, #F0F0F0) !important;
		box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
		left: 12px !important;
		width: calc(100% - 24px) !important;
		overflow: hidden;
	}

	.header > div {
		height: 20px !important;
		align-items: flex-end !important;
	}
}

@include media-breakpoint-up(sm) {
	.mapResultsContainer {
		display: none;
	}
}