._container {
	position: relative;
	width: 100%;
}

.customSpz {
	position: absolute;
	top: 1px;
	left: 1px;
	width: calc(100% - 50px);
	min-height: 40px;
	border-color: transparent;
	border-radius: 20px;
	border-style: solid;
	border-width: 1px;
	background-color: #F3F3F5;
	padding: 0 0 0 10px;

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		color: #6E8CB8;
	}
}