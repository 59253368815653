@import '../../assets/scss/index';

.item {
	display: flex;
	align-items: flex-start;
	align-self: stretch;

	border-radius: 20px;
	box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.03);
	background-color: white;
	cursor: pointer;

	.info {
		display: flex;
		padding: 8px 16px;
		align-items: center;
		gap: 12px;
		flex: 1 0 0;

		.icon {
			color: var(--secondary-light-blue, #6E8CB8);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 100%; /* 16px */
		}

		.title {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: -4px;
			flex: 1 0 0;

			.placeName {
				color: var(--Greys-Grey-6-Dark, #1C1C1E);
				font-feature-settings: 'clig' off, 'liga' off;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
			}

			.parkingInfo {
				color: var(--secondary-praha-grey, #666);
				font-feature-settings: 'clig' off, 'liga' off;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
			}
		}

		.actions {
			display: flex;
			width: auto;
			padding-right: 0px;
			gap: 0.6rem;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			align-self: stretch;

		}
	}

	&.current {
		background-color: #e1e1e1;
	}
}