@import '../../assets/scss/index';

.footer {
	background-color: #3a3c42;
	color: white;
	padding: 15px 30px;
}

.footerWrapper {
	bottom: 0;
	left: 0;
	right: 0;
	height: $footerHeight;
	min-height: $footerHeight;
	z-index: 9;
	grid-row: 2;
	margin-top: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	a {
		color: white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.leftSide {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.links {
			display: flex;
			gap: 30px;
		}

		.bottomText {
			font-size: 15px;
		}
	}

	.rightSide {
		width: 50%;
		display: flex;

		.followContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			height: 24px;
			width: 45%;
		}

		.logos {
			margin-top: 25px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 35%;
			gap: 35px;

			.logoLink {
				display: inline-flex;
				width: 70px;
				height: 70px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

	}

	.followLink {
		display: inline-flex;
		width: 24px;
		height: 24px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.copyright {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	text-align: right;
}

@include media-breakpoint-down(lg) {
	.footerWrapper {
		min-height: auto;
		height: auto;
		flex-direction: column;
		gap: 15px;

		.leftSide {
			width: 100%;

			.bottomText {
				margin-top: 15px;
			}
		}

		.rightSide {
			width: 100%;
		}
	}
}

@include media-breakpoint-down(sm) {
	.footerWrapper {
		.leftSide {
			.links {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 20px;
			}
		}

		.rightSide {
			flex-direction: row;
			flex-wrap: wrap;

			.followContainer {
				width: 100%;
				margin-bottom: 20px;
			}

			.logos {
				width: 50%;
				justify-content: start;
			}
		}
	}

	.copyright {
		text-align: right;
	}
}