@import '../../assets/scss/index';

.mapboxLogo {
    @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: -27px;
        left: calc(-100vw + 64px);
        //padding-top: 5px;
        width: 70px;
        max-height: 20.4px;
    }
    @include media-breakpoint-up(sm) {
        z-index: 99999;
        max-width: 100%;
        //margin: 5px;
    }
}

.mapboxLink {
    @include media-breakpoint-up(sm) {
        max-width: fit-content;
    }
}