@import "~@fontsource/roboto";
@import "~@fontsource/roboto/100.css";
@import "~@fontsource/roboto/300.css";
@import "~@fontsource/roboto/400.css";
@import "~@fontsource/roboto/500.css";
@import "~@fontsource/roboto/700.css";
@import "~@fontsource/roboto/900.css";

@import "variables";
@import "bootstrap";
@import "base";