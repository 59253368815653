@import '../assets/scss/index';

.inputError {
	transition: all 0.3s ease;
	border-radius: 8px;
	background: rgba(235, 64, 52, 0.2) !important;
	color: #CC1818!important;

	input::placeholder {
		color: #CC1818!important;
	}
}

.inputErrorColor {
	color: #CC1818!important;
	text-align: center;
	align-self: center;
}

.inputWarning {
	transition: all 0.3s ease;
	border-radius: 8px;
	background: rgba(255, 163, 64, 0.2) !important;
	color: #ffa340!important;

	input::placeholder {
		color: #ffa340!important;
	}
}

._container {
	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: center;
	min-height: calc(100% - #{$footerHeight});
	padding-top: 64px;

	background: var(--Background, #F0F0F0);

	.wrapper {
		width: 600px;
		max-width: 100%;
		display: flex;
		padding: 16px 16px 0px 16px;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		margin-bottom: 50px;
	}

	h1 {
		color: var(--base-black, #000);
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px; /* 125% */
	}

	h2 {
		color: var(--base-black, #000);
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px; /* 140% */
	}

	.itemGroups {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;

		.header {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
		}

		.itemGroup {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
			align-self: stretch;

			.buttonAddVehicle {
				display: flex;
				border-radius: 50px;
				align-items: center;
				gap: 8px;
				font-size: 12px;
				font-weight: 500;
				line-height: 24px;
				padding: 8px 18px;
				background: $primary;
			}

			.item, .inputItem {
				display: flex;
				padding: 12px 16px;
				justify-content: center;
				align-items: center;
				gap: 12px;
				align-self: stretch;

				border-radius: 20px;
				background: #FFF;

				/* shadows/medium-default */
				box-shadow: 0px 5.586px 9.044px 0px rgba(0, 51, 102, 0.02), 0px 18.762px 30.377px 0px rgba(0, 51, 102, 0.02), 0px 84px 136px 0px rgba(0, 51, 102, 0.04);

				.icon, .labelIcon {
					display: flex;
					justify-content: center;
					align-items: center;
					min-width: 40px;
					height: 40px;

					.removeButton {
						width: 40px;
						height: 40px;
						border-radius: 40px;
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						svg {
							fill: $primary;
						}

						&:hover svg {
							fill: white;
						}
					}
				}

				.labelIcon {
					min-width: 25px;
				}

				.removeAll {
					.removeAllButton {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 40px;
						border-radius: 50px;
						//color: $danger;
						font-size: 12px;
						font-weight: 500;
						line-height: 24px;
						width: max-content;

						svg {
							fill: $danger;
							margin-left: 8px;
						}
					}
				}

				.show {
					.showButton {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 40px;
						color: $primary;
						font-size: 14px;
						font-weight: 500;
						line-height: 24px;
						width: max-content;

						svg {
							fill: $primary;
							margin-left: 8px;
						}
					}
				}

				.cardContent, .cardContentCar, .cardContentRemoveAll {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-weight: 500;
					font-size: 16px;
				}

				.cardContentCar {
					justify-content: flex-start;

					> *:nth-child(2) {
						margin-left: 8px;
						font-size: 14px;
						font-weight: 400;
					}
				}

				.remove {
					display: flex;
					justify-content: center;
					align-items: center;
					min-width: 40px;
					height: 40px;
				}

				.input {
					max-width: 50vw;
					min-width: Min(400px, 60%);
					input {
						width: 100%;
						padding: 8px 16px;
						border: 1px solid #3A3C42;
						border-radius: 20px;
						background: var(--gray-n40, #F3F3F5);

						&::placeholder {
							color: #757575;
							font-style: italic;
							font-size: small;
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
}

.backLink {
	color: $red;
	text-decoration: none;

	svg {
		fill: $red !important;
	}
}

.submitButton {
	width: 100%;
	display: flex;
	padding: 16px 24px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 50px;
	background: $primary;

	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	svg {
		fill: $yellow !important;
	}
}

@include media-breakpoint-down(sm) {
	._container {
		padding-top: 32px;
	}

	.cardContentRemoveAll {
		flex-direction: column;
		align-items: flex-start !important;
	}

	.item {
		padding: 12px 5px 12px 10px !important;
	}
}

.version {
	color: #a5a5a5;
	font-size: 0.7rem;
	align-self: center;
}