@import '../../assets/scss/index';

.wrapper {
	width: 100%;
	display: flex;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	flex: 1 0 0;

	background: #FFF;
	border-radius: 16px;
	box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.03);

	.trashIcon > svg {
		fill: $danger;
	}

	.title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		align-self: stretch;

		h2 {
			font-size: 24px;
			font-weight: 700;
			line-height: 32px;
		}

		div {
			color: #666;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}
	}

	.actions {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 16px;
		align-self: stretch;

		button {
			display: flex;
			height: 44px;
			padding: 10px 22px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			border-radius: 50px;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
		}
	}

}