h1 {
  margin-bottom: 1rem;
  line-height: 2.5rem;
  font-size: 2rem;

  @include media-breakpoint-down(sm) {
    line-height: 2rem;
    font-size: 1.5rem;
  }
}

.textMuted {
  color: #666666;
}

.textNormal {
  font-weight: normal !important;
}

.map-z-index {
  z-index: 0 !important;
}