.errorNotFountWrapper {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.errorNotFount {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		color: gray;
		flex-direction: column;

		h1 {
			font-size: calc(40px + 3vw);
		}

		h2 {
			font-size: calc(28px + 3vw);
		}
	}
}