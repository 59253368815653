@import '../../assets/scss/index';

.item {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 20px;
  box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.03);
  background-color: white;
  margin-bottom: 8px;

  .info {
    display: flex;
    padding: 14px 16px;
    align-items: center;
    gap: 0 12px;
    flex: 1 0 0;
    flex-wrap: wrap;

    .icon {
      color: var(--secondary-light-blue, #6E8CB8);
      text-align: center;
      font-size: 16px;
      width: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 16px */
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: -4px;
      flex: 1 0 0;

      .placeName {
        color: var(--Greys-Grey-6-Dark, #1C1C1E);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }

      .parkingInfo {
        color: var(--secondary-praha-grey, #666);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
    }

    .textEnd {
      display: flex;
      width: auto;
      padding-right: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

    }

    .textMuted {
      color: #666666;
    }

    .textPricing {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      > svg {
        transition: all 0.4s ease;
        margin-left: 10px;
      }
    }
  }

}

.arrowActive {
  svg {
    transform: rotateZ(+180deg);
  }
}

.pricingDetail {
  height: 0;
  top: -12px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.4s linear;
  //transition: all 0.4s ease;
  flex: 0 1 calc(100%);
}

.pricingDetailActive {
  margin-top: 12px;
  height: auto;
  transition: visibility 0s, opacity 0.4s linear;
  visibility: visible;
  opacity: 1;
}



.table {
  text-align: left;
  padding: 0;
  width: 100%;
  margin: 0;
  border: 0;

  tr, td {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.tableHours {
  text-align: center;
  width: 33%;
}

.tablePrice {
  text-align: right;
}

.tableDay {
  width: 19%;
}