@import '../assets/scss/index';

i.icon {
	display: flex;
	//margin: 0 3px;

	svg {
		height: 100%;
		width: 100%;
		fill: #6E8CB8;
	}

	&.activeColor {
		svg, svg * {
			fill: var(--interaction-yellow-default, #FFDF21);
		}
	}

    &.dangerColor {
      svg, svg * {
        fill: var(--interaction-yellow-default, #C32B3E);
      }
    }

    &.blackColor {
      svg, svg * {
        fill: var(--interaction-yellow-default, #000000);
      }
    }

    &.whiteColor {
      svg, svg * {
        fill: var(--interaction-yellow-default, #ffffff);
      }
    }

	&.customSize {
		> svg {
			width: var(--iconSize) !important;
			height: var(--iconSize) !important;
		}
	}

	&.customColor {
		svg, svg * {
			fill: var(--iconColor);
		}
	}
}