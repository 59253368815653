@import '../../assets/scss/index';

.attribution {
    @include media-breakpoint-up(sm) {
        margin: 5px;
    }
    @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: -25px;
        //top: 67.7vh;
        right: 0;
        width: 213.66px;
    }
    font-size: 0.6em;
    background-color: #F0F0F0;
    padding: 3px 5px;
    border-radius: 40px;
    z-index: 99999;
    a {
        text-decoration: none;
    }
}