@import '../../assets/scss/index';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 999;
}

.modalContent {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  pointer-events: auto;
}

.modalHeader h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
}

.modalBody p {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #4a5568;
  line-height: 1.5;
}

.modalButton {
  @include media-breakpoint-down(sm) {
    flex-basis: calc(100% - 10px);
  }
  @include media-breakpoint-up(sm) {
    &:nth-child(3) {
      flex-basis: calc(100%);
    }
    flex-basis: calc(50% - 5px);
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100px;
  background: #1D4F8B;
  border-radius: 50px;
  border: 0;
  font-weight: 500;
  outline: 0;
  height: 40px !important;
  text-decoration: none;

  &:disabled {
    opacity: 40%;
  }
}

