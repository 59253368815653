@import '../assets/scss/index';
@import '../assets/scss/common/FormLayout';

.progressInfo {
  padding: 12px 16px !important;
  flex-direction: column;
}

.progressTitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.progressBar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #96AAB7;
  border-radius: 8px;

  .progress {
    height: 8px;
    background-color: $lightBlue;
    border-radius: 8px;
    width: 0;
    transition: width 0.5s ease-in-out;
  }
}

.notifyInput {
  @include media-breakpoint-up(md) {
    min-width: 260px;
  }
}

.leaving {
  width: 100%;
  font-size: 14px;

  .leavingTitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 500;
  }

  .leavingTitle {
    padding-right: 8px;
    color: $red;
    font-weight: 500;
  }
}

.leavingIcon {
  align-self: start;

  @include media-breakpoint-up(md) {
    align-self: auto;
  }
}

.leavingBtn {
  margin-left: auto;
  margin-right: 0;
  white-space: nowrap;
}

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 50px;
  border: 0;
  font-weight: 500;
  outline: 0;
  height: 50px;
  text-decoration: none;
  font-size: .875rem;

  &.mainBtn {
    background: #1D4F8B;
    color: #fff;
    flex-basis: calc(67% - 5px);
  }

  &.defaultBtn {
    background: transparent;
    border: 1px solid #3A3C42;
    color: #000;

    &:hover {
      //background-color: #C4C6CC;
    }
    flex-basis: calc(33% - 5px);
  }

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}

.buyButton {
  @include media-breakpoint-down(sm) {
    flex-basis: calc(100% - 10px);
  }
  @include media-breakpoint-up(sm) {
    &:nth-child(3) {
      flex-basis: calc(100%);
    }
    flex-basis: calc(66% - 5px);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100px;
  background: #1D4F8B;
  border-radius: 50px;
  border: 0;
  font-weight: 500;
  outline: 0;
  text-decoration: none;

  &:disabled {
    opacity: 40%;
  }
}


