@import '../../assets/scss/index';

.Wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .Container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: gray;
    flex-direction: column;

    h1 {
      font-size: calc(40px + 3vw);
    }

    h2 {
      font-size: calc(28px + 3vw);
    }
  }
}

.containerWrapper {
  display: flex;
  padding-top: 16px;
  padding-bottom: 50px;
  min-height: calc(100% - #{$footerHeight});
  background: #F0F0F0;
}

.container {
  width: 600px;
}

.back {
  display: inline-flex;
  align-items: center;
  color: #C32B3E;
  text-decoration: none;
  margin-bottom: 32px;
	svg {
		fill: $red !important;
	}
}

.title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.caption{
  font-size: 20px;
  text-align: center;
}

.item {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 20px;
  box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.03);
  background-color: white;
  margin-bottom: 12px;

  .info {
    display: flex;
    height: 64px;
    padding: 14px 16px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;

    .icon {
      color: var(--secondary-light-blue, #6E8CB8);
      text-align: center;
      font-size: 16px;
      width: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 16px */
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: -4px;
      flex: 1 0 0;

      .itemTitle {
        color: var(--Greys-Grey-6-Dark, #1C1C1E);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }

    }

    .itemEnd {
      display: flex;
      padding-right: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-up(sm) {
      .itemEndExtended {
        min-width: 305px;
      }
    }
    @include media-breakpoint-down(sm) {
      .itemEndExtended {
        min-width: 190px;
        width: 100%;
      }
    }

    .textMuted {
      color: #666666;
    }

    .text500 {
      font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
      .inputMobileMax {
        max-width: 141.5px;
      }
    }

    .input {
      width: 100%;
      background: #F3F3F5;
      height: 40px;
      min-width: 141.5px;
      border: 1px solid #3A3C42;
      border-radius: 20px;
      padding: 8px 16px 8px 16px;

      &::placeholder {
          color: #757575;
          font-style: italic;
          font-size: small;
          opacity: 0.7;
      }

      &:focus {
        outline: 0;
      }
    }

    .textPricing {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      > svg {
        margin-left: 10px;
      }
    }
  }

}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;


  .saveButton {
    @include media-breakpoint-down(sm) {
      flex-basis: calc(100% - 10px);
    }
    @include media-breakpoint-up(sm) {
      &:nth-child(2) {
        flex-basis: calc(30% - 5px);
      }
      flex-basis: calc(70% - 5px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    background: #1D4F8B;
    border-radius: 50px;
    border: 0;
    font-weight: 500;
    outline: 0;
    height: 50px;

    .actionIcon > svg * {
      fill: white;
    }

    .skipIcon > svg * {
      fill: black;
    }
  }

  .skipButton {
    text-decoration: none;
    border: 1px solid #3A3C42;
    background: none;
    color: black;
  }
}