@import '../assets/scss/index';

.container {

	.parkingZoneDetail {
		z-index: 999999999;
		//display: none;
		width: 343px;
		padding: 16px;
		border-radius: 20px;
		@include media-breakpoint-up(sm) {
			transform: translateY(-50%);
			right: 16px;
			height: fit-content;
			top: 50%;
		}
		box-shadow: 0px 4px 8px 0px #00336629;
		background: #F0F0F0;
		bottom: 16px;
		position: absolute;

		.closeOverlay {
			position: absolute;
			top: -48px;
			height: 38px;
			width: 38px;
			right: 0;
			background: #666666E5;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.curvedTriangle {
			display: block;
			height: 20px;
			width: 20px;
			background-color: #F0F0F0;;
			border: inherit;
			position: absolute;
			top: -10px;
			left: calc(50% - 10px);
			clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
			transform: rotate(135deg);
			border-radius: 0 0 0 0.4em;
		}
	}

	.sidePanelList {
		position: absolute;
		left: 25px;
		top: 25px;
		bottom: 25px;
		width: 332px;
		max-width: calc(100% - 50px);
		border-radius: 8px;
		background: #F0F0F0;
		box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
	}

	.sidePanel {
		z-index: 9;
		position: absolute;
		top: 16px;
		left: 16px;
		width: 364px;

		.mapSearch {

		}

		.mapResults {
			margin-top: 16px;
			max-height: calc(100vh - 250px);
			clip-path: inset(0 round 20px);
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #888B8D;
				border-radius: 3px;
			}

			&.filterIsOpened {
				max-height: calc(100vh - 490px);
			}

			[role="tablist"] {
				top: -16px;
				position: sticky;
				padding: 16px;
				margin: -16px -16px 0 -16px;
				background: #F0F0F0;
			}
		}
	}

	.bottomPanel {
		position: absolute;
		bottom: 32px;
		right: 10px;
		width: 364px;
	}


	.zoomPanel {
		position: absolute;
		z-index: 10;
		bottom: 16px;
		right: 16px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: auto;
		align-items: flex-end;
		gap: 0.5rem;
		max-width: fit-content;
	}

	.zoomButtonContainer {
		display: flex;
		gap: 12px;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	@include media-breakpoint-up(sm) {
		.zoomPanel > *:nth-child(1), .zoomPanel > *:nth-child(4) {
			//flex-basis: 100%;
		}

		.zoomPanel {
			text-align: end;
		}
	}

	.locationPanel {
		z-index: 10;
		position: absolute;
		top: 16px;
		right: 16px;
	}
}

@include media-breakpoint-up(sm) {
	.mapResultsContainer {
		display: none;
	}

	.closeOverlay {
		height: 26px!important;
		width: 26px!important;
		top: -30px!important;
		cursor: pointer;
	}
}

@include media-breakpoint-down(sm) {
	.container {

		.mapResults {
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}

		.parkingZoneDetail {
			width: calc(100vw - 24px);
		}

		.sidePanelList {
			position: absolute;
			left: 25px;
			right: 25px;
			top: 25px;
			width: auto;
			border-radius: 8px;
			background: #F0F0F0;
			box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
		}

		.sidePanel {
			position: absolute;
			top: 12px;
			left: 12px;
			right: 12px;
			width: auto;

			.mapSearchContainer {
				border-radius: 0 8px 8px 0;
			}
		}

		.zoomPanel {
			bottom: 12px;
			right: 12px;
			gap: 0.5rem;
			z-index: 5;
		}
	}

	.zoomPanel {
		position: absolute;
		bottom: calc(env(safe-area-inset-top) - 3px + 142px) !important;
		right: 32px;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.showOnDesktop {
		display: none;
	}
}

.mapContainer {
	position: absolute;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
}

.map {
	z-index: 5;
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	background: white;
}

.mapResultsContainer {

	.mapResults {
		border-radius: 8px;
		background: var(--Background, #F0F0F0) !important;
		box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
		left: 12px !important;
		width: calc(100% - 24px) !important;
		overflow: hidden;
	}
}

.qrCodeContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #F0F0F0;
	box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
	border-radius: 20px;
	z-index: 99999999999;
	flex-direction: column;
	padding: 50px;

	@include media-breakpoint-down(sm) {
		width: calc(100% - 26px);
	}

	.qrCodecloseOverlay {
		position: absolute;
		right: 0;
		top: -48px;
		height: 38px;
		width: 38px;
		cursor: pointer;
		background: #666666E5;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		@include media-breakpoint-up(sm) {
			height: 26px!important;
			width: 26px!important;
			top: -30px!important;
			cursor: pointer;
		}
	}

	.qrCodeText {
		color: black;
		width: 300px;
	}

	.qrReader {
		width: 300px;
		border-radius: 20px;

		video {
			background: black;
			border-radius: 20px!important;
		}
	}
}

.qrCodeOverlay {
	height: 100dvh;
	width: 100%;
	position: fixed;
	z-index: 9999999999;
	left: 0;
	top: 0;
	background: rgba(22, 24, 30, 0.48);
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: center;
	flex-direction: column;
	color: white;
	text-align: center;
}

.blockInfoText {
	display: flex;
	margin-bottom: 0;
	color: #666666;
	height: 52px;
	width: 100%;
	align-items: center;
	justify-content: center;
}