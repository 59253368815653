@import 'assets/scss/index';

.d-flex-center {
	display: flex;
	align-items: center;
}

.btn-primary svg {
	fill: $yellow !important;
}

.btn-outline-danger {
	&:not(:hover) svg {
		fill: $danger !important;
		transition: fill 0.15s ease-in-out;
	}

	&:hover svg {
		fill: white !important;
		transition: fill 0.15s ease-in-out;
	}
}