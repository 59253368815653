@import '../../assets/scss/index';

@include media-breakpoint-down(sm) {
  .myPositionButtonText {
    display: none;
  }
}

.myPositionButton {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: inline-flex;
  padding: 0.375rem 0.75rem;
  border-radius: 40px;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 500ms;
  vertical-align: middle;

  &:hover {
    transition: all 500ms;
    background: $blue!important;
    text-decoration: none;
    color: #FFFFFF;

    .changeColor {
      fill: #FFFFFF;
    }
  }
}

.displayOnDesktop{
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @include media-breakpoint-up(sm) {
    display: inline-flex;
  }
}

.displayOnMobile{
  @include media-breakpoint-down(sm) {
    display: inline-block;
    position: absolute;
    right: 0;
    margin-top: 12px;
  }
  @include media-breakpoint-up(sm) {
    display: none;
  }
}