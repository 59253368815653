@import '../../assets/scss/index';

.mapSearchContainer {
	width: 100%;
	border-radius: 20px;
	background: var(--Background, #F0F0F0);
	box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
	padding: 16px;

	.searchInputTitle {
		margin-bottom: 16px;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0.1px;
	}

	@include media-breakpoint-down(sm) {
		.searchInputTitle {
			display: none;
		}
	}

	.searchInputWrapper {
		display: inline-flex;
		align-items: center;
		gap: 12px;
		align-self: stretch;
		width: 100%;

		.filterButton {
			display: flex;
			width: 50px;
			height: 44px;
			justify-content: center;
			align-items: center;
			gap: 12px;
			cursor: pointer;

			border-radius: 40px;
			border: 1px solid $blue;

			&.opened {
				background-color: $blue;
			}
		}

		.searchInputGroup {
			position: relative;
			width: 100%;
			height: 44px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 1 0 0;

			.icon {
				position: absolute;
				left: 10px;
				z-index: 9999999;

				&::after {
					padding: 5px;
				}
			}
		}
	}

	.filtration {
		margin-top: 16px;

		.filterHeader {
			display: flex;
			height: 24px;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.filterTitle {
				font-size: 16px;
				font-weight: 500;
			}

			.clearFilters {
				cursor: pointer;
				font-weight: 500;
				font-size: 12px;
				color: $red;
			}
		}

		.filterDuration {
			margin-top: 16px;

			.filterTitle {
				font-size: 14px;
				font-weight: 500;
				height: 26px;
			}

			.inputGroups {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 4px;
				align-self: stretch;
				margin-top: 8px;

				> .inputGroup {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 14px;
					min-width: 0;
					flex-wrap: nowrap;
					flex: 1 0 0;

					> input {
						width: 100%;
						max-width: 100%;
					}
				}
			}
		}

		.filterZones {
			margin-top: 16px;

			.filterTitle {
				font-size: 14px;
				font-weight: 500;
				height: 26px;
			}

			.filterOptions {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				> * {
					width: 50%;
				}
			}
		}
	}

	.inputGroup {
		width: 100%;
		height: 44px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1 0 0;

		border-radius: 4px;
		//border: 1px solid var(--secondary-light-blue, #6E8CB8);
		background: var(--gray-n40, #F3F3F5);

		> input {
			width: 100%;
			height: 100%;
			padding: 10px 20px;
			color: $blue;
			background: transparent;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			border: 0;

			&::placeholder {
				color: var(--secondary-light-blue, #6E8CB8);
				opacity: 1;
			}
		}
	}

	.checkboxGroup {
		.checkbox {
			margin-bottom: 8px;

			.zoneColorDot {
				margin-left: 4px;
			}
		}
	}
}

.logoWrapper {
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.iconOffset::after {
	padding: 5px;
}