.wrapper {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 12px;
	font-size: 14px;
	border-radius: 5px;
	user-select: none;

	.checkbox {
		border-radius: 5px;
		cursor: pointer;
	}

	input {
		display: none;
	}
}

