.actions {
  margin-top: 14px;
  display: flex;
  column-gap: 12px;

  .addToFavorite {
    background-color: #F0F0F0;
    border: 1px solid #1D4F8B;
    height: 44px;
    width: 44px;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 500ms;

    &.active {
       background-color: #1D4F8B;
    }
  }

  .buyTicket {
    background: #1D4F8B;
    color: #fff;
    text-decoration: none;
    height: 44px;
    //width: calc(100% - 44px);
    width: 100%;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 50px;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 500ms;
  }
}

.disabledLink {
  pointer-events: none;
  filter: grayscale(100%);
}
