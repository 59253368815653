@import '../../assets/scss/index';

.zoomButton {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 40px;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    background: $blue!important;
    text-decoration: none;

    .changeColor {
      fill: white;
    }
  }
}