.containerWrapper {
  display: flex;
  padding-bottom: 3rem;

  @include media-breakpoint-up(md) {
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    padding-top: 48px;
  }

  justify-content: center;
  min-height: calc(100% - #{$footerHeight});
  background: #F0F0F0;
}

.back {
  display: inline-flex;
  align-items: center;
  color: #C32B3E;
  text-decoration: none;
  margin-bottom: 8px;
}

.container {
  width: 600px;
}

.header {
  font-weight: 700;
  margin-bottom: 8px;
}

@include media-breakpoint-down(sm) {
  .titleMobile {
    flex: none!important;
  }
}

@include media-breakpoint-down(sm) {
  .timeInfo {
    flex-direction: column;
  }
}

.formActions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .mainButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    background: $primary;
    border-radius: 40px;
    border: 0;
    font-weight: 500;
    outline: 0;
    height: 50px;
  }

  .appleButton {
    background: #000000;
    font-weight: 600;
    font-family: -apple-system, system-ui, BlinkMacSystemFont;
    color: white;
  }

  .googleButton {
    background: white;
    font-weight: 500;
    color: #000000;
  }
}

.item {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 20px;
  box-shadow: 0 10px 28px 0 rgba(0, 0, 0, 0.03);
  background-color: white;
  margin-bottom: 12px;

  .info {
    display: flex;
    height: 64px;
    padding: 14px 16px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;

    .icon {
      color: var(--secondary-light-blue, #6E8CB8);
      text-align: center;
      font-size: 16px;
      width: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 16px */
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: -4px;
      flex: 1 0 0;

      .itemTitle {
        color: var(--Greys-Grey-6-Dark, #1C1C1E);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }

    }

    .itemEnd {
      display: flex;
      padding-right: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-up(sm) {
      .itemEndExtended {
        min-width: 232px;
      }
    }
    @include media-breakpoint-down(sm) {
      .itemEndExtended {
        width: 100%;
      }
    }

    .text500 {
      font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
      .inputMobileMax {
        max-width: 141.5px;
      }
    }

    .input {
      width: 100%;
      background: #F3F3F5;
      height: 40px;
      min-width: 141.5px;
      border: 0;
      border-radius: 4px;
      padding: 8px 16px 8px 16px;
      &::placeholder {
        color: #6E8CB8;
      }

      &:focus {
        border: 0;
        outline: 0;
      }
    }

    .textPricing {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      > svg {
        margin-left: 10px;
      }
    }
  }

}