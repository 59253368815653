@import '../../assets/scss/index';

.mapResultsContainer {
	width: 100%;
	border-radius: 20px;
	background: var(--Background, #F0F0F0);
	box-shadow: 0px 4px 8px 0px rgba(0, 51, 102, 0.16), 0px 16px 16px 0px rgba(0, 51, 102, 0.08);
	padding: 16px;

	.tabList {
		display: flex;
		padding-bottom: 4px;
		align-items: center;
		gap: -1px;
		align-self: stretch;
		padding-left: 0;

		> .tabItem {
			display: flex;
			padding: 8px 14px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			flex: 1 0 0;
			cursor: pointer;
			font-size: 12px;

			border: 1px solid var(--secondary-light-blue, #6E8CB8);
			color: $primary;

			&:nth-child(1) {
				border-radius: 4px 0px 0px 4px;
			}

			&:nth-child(3) {
				border-radius: 0px 4px 4px 0px;
			}

			&.selected {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), $primary;
				border: 1px solid var(--secondary-light-blue, $primary);
				color: white;

				.tabIcon > svg * {
					fill: var(--interaction-yellow-default, #FFDF21);
				}
			}

			&:focus-visible {
				outline: 0;
			}
		}
	}

	.items {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		align-self: stretch;
		overflow: hidden;
	}

	.wrapperLoadNext {
		display: flex;
		padding: 12px 0px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		align-self: stretch;

		.loadNextButton {
			display: flex;
			width: 178px;
			height: 44px;
			padding: 10px 22px;
			justify-content: center;
			align-items: center;
			gap: 8px;

			border-radius: 4px;
			border: 1px solid var(--gray-n200, #C4C6CC);

			color: var(--base-black, #000);
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.mapResultsContainer {
		box-shadow: none;
		border-radius: 0;
	}
}

.parkingSessions {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	overflow: hidden;
	margin-bottom: 8px;
}