@import '../assets/scss/index';
@import '../assets/scss/common/FormLayout';

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 40px;
  border: 0;
  font-weight: 500;
  outline: 0;
  height: 50px;
  text-decoration: none;
  font-size: .875rem;

  &.mainBtn {
    background: #1D4F8B;
    color: #fff;
    flex-basis: calc(67% - 5px);
  }

  &.defaultBtn {
    border-radius: 40px;
    background: transparent;
    border: 1px solid #3A3C42;
    color: #000;

    &:hover {
      //background-color: #C4C6CC;
    }
    flex-basis: calc(33% - 5px);
  }

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}

.buyButton {
  @include media-breakpoint-down(sm) {
    flex-basis: calc(100% - 10px);
  }
  @include media-breakpoint-up(sm) {
    &:nth-child(3) {
      flex-basis: calc(100%);
    }
    flex-basis: calc(66% - 5px);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100px;
  background: #1D4F8B;
  border-radius: 50px;
  border: 0;
  font-weight: 500;
  outline: 0;
  text-decoration: none;

  &:disabled {
    opacity: 40%;
  }
}