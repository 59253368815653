.overlay {
    height: 100dvh;
    width: 100%;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    background: rgba(22, 24, 30, 0.48);
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    flex-direction: column;
    color: white;
    text-align: center;

    p {
        font-weight: 500;
        text-shadow: 0.4px 0.47px 0 rgba(0, 0, 0, 0.09);
    }

    svg {
        margin-bottom: 2rem;
        width: 48px;
        height: 48px;
    }
}

.spinner_loader{
    fill: rgb(195, 43, 62);
    transform-origin: center;
    border-radius: 8px;
    animation: spinner_AtaB 2s infinite linear;
}

.spinner_circle {
    fill: rgba(184, 190, 204, 1);
}

@keyframes spinner_AtaB{100%{transform:rotate(360deg)}}
