@import '../assets/scss/index';

$navbarHeightMobile: 80px;
$navbarHeightDesktop: 86px;
$paddingMobile: 12px;
$paddingDesktop: 19px;

.layout {
	position: fixed;
	inset: 0;
	display: flex;
	flex-direction: column;
	background-color: #F0F0F0;
	height: 100dvh;
}

.btnMobile {
	border: 1px solid rgba(102, 102, 102, 0.898) !important;
	border-radius: 50px !important;
	font-size: 17px !important;

	&:hover {
		border: 1px solid rgba(102, 102, 102, 0.898) !important;
	}
}

.mobileNavbar {
	position: absolute;
	z-index: 11;
	background: #FFFFFF;
	width: 100%;
	padding: calc(32px + 2px) $paddingMobile 32px $paddingMobile;
	top: $navbarHeightMobile - 2px;
	gap: 32px;
	flex-direction: column;
}

.mobileNavbarOverlay {
	position: absolute;
	z-index: 11;
	background: rgba(22, 24, 30, 0.48);
	width: 100%;
	height: 100%;
	top: 0;
}

.navbarActive {
	z-index: 11;
}

.navbar {
	position: relative!important;
	background-color: white;
	min-height: $navbarHeightMobile;
	padding: 0 $paddingMobile;

	.logo {
		cursor: pointer;
		-webkit-user-drag: none;
		$logoMargin: 19px;
		height: $navbarHeightMobile - ($logoMargin * 2);
	}

	.menu {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
	}

	.btnMenuOpen {
		display: flex;
		width: 50px;
		height: 44px;
		justify-content: center;
		align-items: center;
		gap: 12px;
		cursor: pointer;
		border-radius: 40px;
		border: 0;
		background: #1D4F8B;

		> svg {
			fill: #FFFFFF;
		}
	}

	.mobileMenuContainer {
		z-index: 9;
		display: block;
		position: fixed;
		inset: 0;
		background-color: rgba(255, 255, 255, 0.54);
		backdrop-filter: blur(25px);
		opacity: 0;
		pointer-events: none;
		transition: opacity .2s;

		&.opened {
			opacity: 1;
			pointer-events: all;
		}

		.closeMenuButton {
			position: absolute;
			top: 25px;
			right: 22px;
			width: 40px;
			height: 40px;
			font-size: 20px;
			background-color: lightgray;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #505050;
		}

		.mobileMenuHeader {
			display: flex;
			align-items: center;
			justify-content: center;
			height: $navbarHeightMobile;

			h2 {
				margin-bottom: 0;
			}
		}

		.mobileMenu {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			gap: 5px;

			a {
				background-color: white;
				height: 50px;
			}
		}
	}

	.menu, .mobileMenu {
		> a {
			font-size: 14px;
			padding: 0 14px;
			gap: 8px;
			height: 40px;
			align-items: center;
			justify-content: center;
			display: inline-flex;
		}

		> .btn-login {
			background-color: $red;
			color: white;
		}

		> .btn-menu {
			background-color: $primary;
			color: white;
		}
	}
}

.sidePanel {
	z-index: 9;
	position: relative;
	flex: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.sidePanelWrapper {
	flex: 1;
	max-width: 100vw;
}

.sidePanelMap {
	overflow: hidden;
}

@include media-breakpoint-up(sm) {
	.navbar {
		height: $navbarHeightDesktop;
		padding: 0 $paddingDesktop;

		.logo {
			$logoMargin: 19px;
			height: $navbarHeightDesktop - ($logoMargin * 2);
		}

		.mobileMenuContainer .mobileMenuHeader {
			height: $navbarHeightDesktop;
		}

		.mobileMenuContainer {
			display: none;
		}
	}
}

@include media-breakpoint-down(sm) {
	.navbar {
		.menu {
			> a:last-child {
				margin-right: 2px;
			}
		}

		.mobileMenu {
			> a {
				width: calc(100% - 40px);
				margin-right: 0;
			}
		}
	}
}

.btnIcon {
	> svg * {
		fill: #FFDF21;
	}
}