@import './assets/scss/index';

html {

}

body {
  margin: 0;
  font-family: Roboto, serif;
  min-height: 100dvh;
  max-height: 100dvh;
  padding-bottom: env(safe-area-inset-bottom);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
